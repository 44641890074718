import axios from 'axios';
import {API_BASE_URL} from '../config';

class ApiClient {
  private static instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  public static login = async (username: string, password: string) =>
    await this.instance.post(`/auth/login`, {username, password});

  public static me = async () =>
    await this.instance.get(`/auth/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

  public static tarifeler = async (tip: string) =>
    await this.instance.get(`/online-basvuru/tarifeler/${tip}`);

  public static cepTelefonuKodGonder = async (oturumId: string, telefon: string) =>
    await this.instance.post(`/online-basvuru/gsm-kod-gonder`, {oturumId, telefon});

  public static cepTelefonuKodKontrol = async (oturumId: string, telefon: string, kod: string) =>
    await this.instance.post(`/online-basvuru/gsm-kod-kontrol`, {oturumId, telefon, kod});

  public static vericiIsletmeleri = async () =>
    await this.instance.get(`/online-basvuru/verici-isletmecileri`);

  public static sehirler = async () =>
    await this.instance.get('/adres/sehirler');

  public static ilceler = async (kod: string) =>
    await this.instance.get(`/adres/ilceler?id=${kod}`);

  public static bucaklar = async (kod: string) =>
    await this.instance.get(`/adres/bucaklar?id=${kod}`);

  public static koyler = async (kod: string) =>
    await this.instance.get(`/adres/koyler?id=${kod}`);

  public static mahalleler = async (kod: string) =>
    await this.instance.get(`/adres/mahalleler?id=${kod}`);

  public static sokaklar = async (kod: string) =>
    await this.instance.get(`/adres/sokaklar?id=${kod}`);

  public static binalar = async (kod: string) =>
    await this.instance.get(`/adres/binalar?id=${kod}`);

  public static daireler = async (kod: string) =>
    await this.instance.get(`/adres/daireler?id=${kod}`);

  public static adresKodu = async (kod: string) => {
    return await this.instance.get(`/adres/adres-kodu?id=${kod}`);
  }

  public static xdslHizmetNumarasiDogrulama = async (xdsl: any) => {
    return await this.instance.get(`/adres/xdsl-kontrol?xdsl=${xdsl}`);
  }

  public static kimlikDogrulama = async (data: any) => {
    return await this.instance.post(`/online-basvuru/kimlik-dogrulama`, data);
  }

  public static onlineBasvuru = async (data: any) => {
    return await this.instance.post(`/online-basvuru/basvuru`, data);
  }
}

export default ApiClient;

import {StateCreator} from 'zustand';
import {AppSliceType} from './';

export type DealerType = {
  id: string;
  username: string;
} | null

export type DealerSliceType = {
  dealer: DealerType;
  setDealer: (dealer: DealerType) => void;
}

const createDealerSlice: StateCreator<AppSliceType, [], [], DealerSliceType> = (set) => ({
  dealer: null,
  setDealer: (dealer) => set({dealer}),
})

export default createDealerSlice;

import {Image} from '@mantine/core';
import logo from '../../assets/img/logo.png';

type PropsType = {
  title: string;
  className?: string;
}

const Header = ({title, className}: PropsType) => {
  return (
    <header className={className}>
      <Image src={logo} alt="logo" m="auto" width="281"/>
      <h1>{title}</h1>
    </header>
  )
}

export default Header;

import {ReactNode, useEffect, useRef, useState} from 'react';
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  createStyles,
  FileButton,
  Flex,
  Grid,
  Group,
  Image,
  Input,
  Loader,
  LoadingOverlay,
  Radio,
  Select,
  Text,
  TextInput
} from '@mantine/core';
import {useForm, yupResolver} from '@mantine/form';
import {notifications} from '@mantine/notifications';
import {
  IconAlertCircle,
  IconAlertTriangle, IconCheck,
  IconFile,
  IconHome, IconLogout,
  IconRefresh,
  IconSignature,
  IconTrash,
  IconX
} from '@tabler/icons-react';
import {IMaskInput} from 'react-imask';
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import Yup from '../../utils/yup';
import dayjs from '../../utils/dayjs';
import {SignatureModal} from '../partials';
import ApiClient from '../../utils/ApiClient';
import useAppStore from '../../store';
import {fileToBase64, phoneFormat, validateTcIdentityNumber} from '../../utils/helpers';
import {SelectOptionType} from '../../types';
import {NEW_CONNECTION_COST} from '../../config';
import {GsmCheckFormModal} from './';
import {EMAIL_REGEX_PATTERN} from "../../utils/constants";

const useStyles = createStyles(theme => ({
  fieldset: {
    border: `1px solid ${theme.colors.gray[3]}`,
    padding: theme.spacing.md,
    marginBottom: theme.spacing.md,
    '> legend': {
      padding: theme.spacing.xs,
      fontWeight: 500,
      fontSize: theme.fontSizes.md
    }
  },
  gsm: {
    position: 'relative',
    '.dogrulama': {
      position: 'absolute',
      top: 25,
      right: 0
    }
  },
  phoneInput: {
    '> .prefix': {
      padding: 6,
      width: 40,
      backgroundColor: theme.colors.gray[0],
      borderTopLeftRadius: theme.radius.sm,
      borderBottomLeftRadius: theme.radius.sm,
      border: `1px solid ${theme.colors.gray[3]}`,
      fontSize: theme.fontSizes.sm,
      cursor: 'default'
    },
    '> .mantine-Input-wrapper': {
      width: 'calc(100% - 40px)',
      'input': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }
    }
  },
  altyapiSorgu: {
    paddingTop: 30
  },
  altyapiYok: {
    color: theme.colors.red[6]
  }
}));

const ApplicationForm = () => {
  const {classes} = useStyles();
  const dealer = useAppStore(state => state.dealer);
  const setDealer = useAppStore(state => state.setDealer);
  const session = useAppStore(state => state.session);
  const setSession = useAppStore(state => state.setSession);
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [completedAt, setCompletedAt] = useState<string | null>(null);
  const [signModalOpened, setSignModalOpened] = useState(false);
  const [gsmFormModalOpened, setGsmFormModalOpened] = useState(false);
  const [vergiLevhasiDosya, setVergiLevhasiDosya] = useState<File | null>(null);
  const [imzaSirkusuDosya, setImzaSirkusuDosya] = useState<File | null>(null);
  const [kimlikOnYuzDosya, setKimlikOnYuzDosya] = useState<File | null>(null);
  const [kimlikArkaYuzDosya, setKimlikArkaYuzDosya] = useState<File | null>(null);
  const [isletmeciDegisiklikFormuDosya, setIsletmeciDegisiklikFormuDosya] = useState<File | null>(null);
  const [abonelikSozlesmeDosyalar, setAbonelikSozlesmeDosyalar] = useState<File[]>([]);
  const [engelliIndirim, setEngelliIndirim] = useState<boolean>(false);
  const [engelliSehitGaziKimlikRaporOnYuzDosya, setEngelliSehitGaziKimlikRaporOnYuzDosya] = useState<File | null>(null);
  const [engelliSehitGaziKimlikRaporArkaYuzDosya, setEngelliSehitGaziKimlikRaporArkaYuzDosya] = useState<File | null>(null);
  const [engelliSehitGaziYakinNufusCuzdaniOnYuzDosya, setEngelliSehitGaziYakinNufusCuzdaniOnYuzDosya] = useState<File | null>(null);
  const [engelliSehitGaziYakinNufusCuzdaniArkaYuzDosya, setEngelliSehitGaziYakinNufusCuzdaniArkaYuzDosya] = useState<File | null>(null);
  const [xdslSorgu, setXdslSorgu] = useState<boolean>(false);
  const [xdslResult, setXdslResult] = useState<{
    status: 'success' | 'error',
    icon: ReactNode,
    color: string,
    message: string,
    company?: {
      id: number,
      name: string
    }
  } | null>(null);
  const [adresSorgu, setAdresSorgu] = useState<boolean>(false);
  const [vericiIsletmecileri, setVericiIsletmecileri] = useState<SelectOptionType[]>([]);
  const [sehirler, setSehirler] = useState<SelectOptionType[]>([]);
  const [selectedSehir, setSelectedSehir] = useState<SelectOptionType | null>();
  const [ilceler, setIlceler] = useState<SelectOptionType[]>([]);
  const [selectedIlce, setSelectedIlce] = useState<SelectOptionType | null>();
  const [koyler, setKoyler] = useState<SelectOptionType[]>([]);
  const [selectedKoy, setSelectedKoy] = useState<SelectOptionType | null>();
  const [bucaklar, setBucaklar] = useState<SelectOptionType[]>([]);
  const [selectedBucak, setSelectedBucak] = useState<SelectOptionType | null>();
  const [mahalleler, setMahalleler] = useState<SelectOptionType[]>([]);
  const [selectedMahalle, setSelectedMahalle] = useState<SelectOptionType | null>();
  const [sokaklar, setSokaklar] = useState<SelectOptionType[]>([]);
  const [selectedSokak, setSelectedSokak] = useState<SelectOptionType | null>();
  const [binalar, setBinalar] = useState<SelectOptionType[]>([]);
  const [selectedBina, setSelectedBina] = useState<SelectOptionType | null>();
  const [daireler, setDaireler] = useState<SelectOptionType[]>([]);
  const [selectedDaire, setSelectedDaire] = useState<SelectOptionType | null>();
  const [adres, setAdres] = useState<string | null>();
  const [bbkKod, setBbkKod] = useState<string | null>();
  const [uavt, setUavt] = useState<string | null>();
  const [tarifeTipi, setTarifeTipi] = useState<'fiber' | 'dsl' | 'yok' | null>(null);
  const [dslTarifeTipi, setDslTarifeTipi] = useState<'adsl' | 'vdsl' | null>(null);
  const [maxHiz, setMaxHiz] = useState<number | null>();
  const [portDurum, setPortDurum] = useState<boolean>(false);
  const [isAltyapiSorgu, setIsAltyapiSorgu] = useState(false);
  const [tarifeler, setTarifeler] = useState<SelectOptionType[]>([]);
  const [identityVerified, setIdentityVerified] = useState<boolean>(true);
  const [isGsmVerified, setIsGsmVerified] = useState(false);
  const kisiselBilgilerRef = useRef<HTMLLegendElement | null>(null);
  const ilceRef = useRef<HTMLInputElement | null>(null);
  const bucakRef = useRef<HTMLInputElement | null>(null);
  const koyRef = useRef<HTMLInputElement | null>(null);
  const mahalleRef = useRef<HTMLInputElement | null>(null);
  const sokakRef = useRef<HTMLInputElement | null>(null);
  const binaRef = useRef<HTMLInputElement | null>(null);
  const daireRef = useRef<HTMLInputElement | null>(null);
  const tarifeRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setSession({
      id: uuidv4(),
    })
  }, [setSession])

  const form = useForm({
    validate: yupResolver(Yup.object().shape({
      bayi_giris: Yup.boolean()
        .nullable(),
      bayi_id: Yup.number()
        .nullable(),
      basvuru_tipi: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız')
        .oneOf(['1', '2'], 'Seçim yapmalısınız'),
      xdsl_hizmet_numarasi: Yup.string()
        .nullable()
        .when('basvuru_tipi', (basvuru_tipi: string[], schema) => {
          if (basvuru_tipi && basvuru_tipi[0] === '2') {
            return schema.required('Değer girmelisiniz');
          }
          return schema;

        })
        .test('xdsl_hizmet_numarasi', 'Geçerli bir xDSL hizmet numarası girmelisiniz', value => {
          /*
          if (value) {
            return value.startsWith('18') || value.startsWith('88') || value.startsWith('178') || value.startsWith('179');
          }
          */
          return true;
        }),
      firma: Yup.string()
        .nullable()
        .when('basvuru_tipi', (basvuru_tipi: string[], schema) => {
          if (basvuru_tipi && basvuru_tipi[0] === '2') {
            return schema.required('Seçim yapmalısınız');
          }

          return schema;
        }),
      abonelik_tipi: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız')
        .oneOf(['1', '2'], 'Seçim yapmalısınız'),
      ad: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '1') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        }),
      soyad: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '1') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        }),
      tc_kimlik_no: Yup.string()
        .nullable()
        .required('Değer girmelisiniz')
        .test('tc_kimlik_no', 'Geçerli bir TC kimlik numarası girmelisiniz', value => {
          return validateTcIdentityNumber(value);
        }),
      kimlik_seri_no: Yup.string()
        .nullable()
        .required('Değer girmelisiniz'),
      anne_adi: Yup.string()
        .nullable()
        .required('Değer girmelisiniz'),
      baba_adi: Yup.string()
        .nullable()
        .required('Değer girmelisiniz'),
      cinsiyet: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '1') {
            return schema.required('Seçim yapmalısınız');
          }

          return schema;
        })
        .oneOf(['erkek', 'kadin'], 'Seçim yapmalısınız'),
      dogum_tarihi: Yup.string()
        .nullable()
        .required('Değer girmelisiniz')
        .test('dogum_tarihi', 'Geçerli bir tarih girmelisiniz', value => {
          if (value) {
            return dayjs(value, 'DD.MM.YYYY', true).isValid();
          }
          return true;
        }),
      firma_unvani: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        }),
      vergi_dairesi: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        }),
      vergi_numarasi: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        })
        .matches(/^[0-9]{10}$/, 'Geçerli bir vergi numarası girmelisiniz'),
      yetkili_adi: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        }),
      yetkili_soyadi: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('Değer girmelisiniz');
          }

          return schema;
        }),
      eposta: Yup.string()
        .nullable()
        .required('Değer girmelisiniz')
        .matches(EMAIL_REGEX_PATTERN, 'Geçerli bir e-posta adresi girmelisiniz'),
      telefon: Yup.string()
        .nullable()
        .required('Değer girmelisiniz')
        .test('telefon', 'Geçerli bir telefon numarası girmelisiniz', value => {
          if (value) {
            return phoneFormat(value).length === 13;
          }
          return true;
        }),
      engelli_sehit_gazi_indirimi: Yup.boolean()
        .nullable(),
      engelli_sehit_gazi_yakin: Yup.string()
        .nullable()
        .test('engelli_sehit_gazi_yakin', 'Seçim yapmalısınız', value => {
          if (engelliIndirim) {
            return value === '1' || value === '2';
          }
          return true;
        }),
      imza: Yup.string()
        .test('imza', 'İmza atmalısınız', value => {
          if (dealer !== null) {
            return true;
          }

          if (value) {
            return value.length > 0;
          }

          return false;
        }),
      abonelik_sozlesmesi: Yup.array()
        .when('bayi_giris', (bayi_giris: boolean[], schema) => {
          if (bayi_giris && bayi_giris[0]) {
            return schema.min(1, 'Abonelik sözleşmesi için en az 1 dosya seçmelisiniz');
          }

          return schema;
        }),
      isletmeci_degisiklik: Yup.string()
        .nullable()
        .when(['bayi_giris', 'basvuru_tipi'], (props: any[], schema) => {
          if (props && props[0] && props[1] === '2') {
            return schema.required('İşletmeci değişiklik formu için dosya seçmelisiniz');
          }

          return schema;
        }),
      vergi_levhasi: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('Vergi levhası için dosya seçmelisiniz');
          }

          return schema;
        }),
      imza_sirkusu: Yup.string()
        .nullable()
        .when('abonelik_tipi', (abonelik_tipi: string[], schema) => {
          if (abonelik_tipi && abonelik_tipi[0] === '2') {
            return schema.required('İmza sirküsü için dosya seçmelisiniz');
          }

          return schema;
        }),
      kimlik_on_yuz: Yup.string()
        .nullable()
        .required('Abone kimlik ön yüzü için dosya seçmelisiniz'),
      kimlik_arka_yuz: Yup.string()
        .nullable()
        .required('Abone kimlik arka yüzü için dosya seçmelisiniz'),
      engelli_sehit_gazi_kimlik_rapor_on_yuz: Yup.string()
        .nullable()
        .test('engelli_sehit_gazi_kimlik_rapor_on_yuz', 'Engelli, şehit veya gazi kimlik ön yüzü için dosya seçmelisiniz', value => {
          if (engelliIndirim) {
            return value !== undefined && value !== null && value !== '';
          }
          return true;
        }),
      engelli_sehit_gazi_kimlik_rapor_arka_yuz: Yup.string()
        .nullable()
        .test('engelli_sehit_gazi_kimlik_rapor_arka_yuz', 'Engelli, şehit veya gazi kimlik arka yüzü için dosya seçmelisiniz', value => {
          if (engelliIndirim) {
            return value !== undefined && value !== null && value !== '';
          }
          return true;
        }),
      engelli_sehit_gazi_nufus_cuzdani_on_yuz: Yup.string()
        .nullable()
        .when('engelli_sehit_gazi_yakin', (item: any[], schema) => {
          if (engelliIndirim && item && item[0] === '2') {
            return schema.required('Engelli, şehit veya gazi nüfus cüzdanı ön yüzü için dosya seçmelisiniz');
          }

          return schema;
        }),
      engelli_sehit_gazi_nufus_cuzdani_arka_yuz: Yup.string()
        .nullable()
        .when('engelli_sehit_gazi_yakin', (item: any[], schema) => {
          if (engelliIndirim && item && item[0] === '2') {
            return schema.required('Engelli, şehit veya gazi nüfus cüzdanı arka yüzü için dosya seçmelisiniz');
          }

          return schema;
        }),
      sehir: Yup.string()
        .nullable().required('Seçim yapmalısınız'),
      ilce: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      mahalle: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      bucak: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      koy: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      sokak: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      bina: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      daire: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      tarife: Yup.string()
        .nullable()
        .required('Seçim yapmalısınız'),
      modem: Yup.string()
        .nullable()
        .when('basvuru_tipi', (basvuru_tipi: string[], schema) => {
          if ((basvuru_tipi && basvuru_tipi[0] === '1') && tarifeTipi === 'dsl') {
            return schema.required('Seçim yapmalısınız');
          }

          return schema;
        }),
      sabit_telefon_var_mi: Yup.string()
        .nullable()
        .test('sabit_telefon_var_mi', 'Seçim yapmalısınız', value => {
          return value === '0' || value === '1';
        }),
      sabit_telefon: Yup.string()
        .nullable()
        .when('sabit_telefon_var_mi', (sabit_telefon_var_mi: string[], schema) => {
          if (sabit_telefon_var_mi && sabit_telefon_var_mi[0] === '1') {
            return schema.required('Değer girmelisiniz');
          }

          return schema.test('sabit_telefon', 'Geçerli bir telefon girmelisiniz', (value: any) => {
            if (sabit_telefon_var_mi && sabit_telefon_var_mi[0] === '0') {
              return true;
            }

            return value && value.length === 19;
          });
        }),
      sartlar: Yup.boolean()
        .oneOf([true], 'Bu alanı onaylamalısınız'),
    })),
    initialValues: {
      bayi_giris: dealer !== null,
      bayi_id: dealer !== null ? dealer.id : null,
      abonelik_tipi: '1',
      sabit_telefon_var_mi: '0',
    },
  });

  const handleClearSignature = () => {
    form.setFieldValue('imza', '');
  }

  useEffect(() => {
    if (vergiLevhasiDosya === null) {
      form.setFieldValue('vergi_levhasi', null);
    } else {
      ((async () => {
        const data = await fileToBase64(vergiLevhasiDosya);
        if (data) {
          form.setFieldValue('vergi_levhasi', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [vergiLevhasiDosya]);

  const handleClearVergiLevhasi = () => {
    setVergiLevhasiDosya(null);
    form.setFieldValue('vergi_levhasi', null);
  }

  useEffect(() => {
    if (imzaSirkusuDosya === null) {
      form.setFieldValue('imza_sirkusu', null);
    } else {
      ((async () => {
        const data = await fileToBase64(imzaSirkusuDosya);
        if (data) {
          form.setFieldValue('imza_sirkusu', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [imzaSirkusuDosya]);

  const handleClearImzaSirkusu = () => {
    setImzaSirkusuDosya(null);
    form.setFieldValue('imza_sirkusu', null);
  }

  useEffect(() => {
    if (isletmeciDegisiklikFormuDosya === null) {
      form.setFieldValue('isletmeci_degisiklik', null);
    } else {
      ((async () => {
        const data = await fileToBase64(isletmeciDegisiklikFormuDosya);
        if (data) {
          form.setFieldValue('isletmeci_degisiklik', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [isletmeciDegisiklikFormuDosya]);

  const handleClearIsletmeciDegisiklikFormu = () => {
    setIsletmeciDegisiklikFormuDosya(null);
    form.setFieldValue('isletmeci_degisiklik', null);
  }

  useEffect(() => {
    if (kimlikOnYuzDosya === null) {
      form.setFieldValue('kimlik_on_yuz', null);
    } else {
      ((async () => {
        const data = await fileToBase64(kimlikOnYuzDosya);
        if (data) {
          form.setFieldValue('kimlik_on_yuz', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [kimlikOnYuzDosya]);

  const handleClearKimlikOnYuz = () => {
    setKimlikOnYuzDosya(null);
    form.setFieldValue('kimlik_on_yuz', null);
  }

  useEffect(() => {
    if (kimlikArkaYuzDosya === null) {
      form.setFieldValue('kimlik_arka_yuz', null);
    } else {
      ((async () => {
        const data = await fileToBase64(kimlikArkaYuzDosya);
        if (data) {
          form.setFieldValue('kimlik_arka_yuz', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [kimlikArkaYuzDosya]);

  const handleClearKimlikArkaYuz = () => {
    setKimlikArkaYuzDosya(null);
    form.setFieldValue('kimlik_arka_yuz', null);
  }

  useEffect(() => {
    if (abonelikSozlesmeDosyalar.length === 0) {
      form.setFieldValue('abonelik_sozlesmesi', []);
    } else {
      ((async () => {
        const data: any[] = await Promise.all(abonelikSozlesmeDosyalar.map(async (file: File) => {
          return await fileToBase64(file);
        }));
        if (data) {
          form.setFieldValue('abonelik_sozlesmesi', data.map((item: any) => item.toString()));
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [abonelikSozlesmeDosyalar]);

  const handleAddAbonelikSozlesmesiDosyalar = (files: File[]) => {
    setAbonelikSozlesmeDosyalar(abonelikSozlesmeDosyalar.concat(files));
  }

  const handleClearAbonelikSozlesmesi = () => {
    setAbonelikSozlesmeDosyalar([]);
    form.setFieldValue('abonelik_sozlesmesi', []);
  }

  const handleClearEngelliSehitGaziKimlikOnYuz = () => {
    setEngelliSehitGaziKimlikRaporOnYuzDosya(null);
    form.setFieldValue('engelli_sehit_gazi_kimlik_rapor_on_yuz', null);
  }

  useEffect(() => {
    if (engelliSehitGaziKimlikRaporOnYuzDosya === null) {
      form.setFieldValue('engelli_sehit_gazi_kimlik_rapor_on_yuz', null);
    } else {
      ((async () => {
        const data = await fileToBase64(engelliSehitGaziKimlikRaporOnYuzDosya);
        if (data) {
          form.setFieldValue('engelli_sehit_gazi_kimlik_rapor_on_yuz', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [engelliSehitGaziKimlikRaporOnYuzDosya]);

  const handleClearEngelliSehitGaziKimlikArkaYuz = () => {
    setEngelliSehitGaziKimlikRaporArkaYuzDosya(null);
    form.setFieldValue('engelli_sehit_gazi_kimlik_rapor_arka_yuz', null);
  }

  useEffect(() => {
    if (engelliSehitGaziKimlikRaporArkaYuzDosya === null) {
      form.setFieldValue('engelli_sehit_gazi_kimlik_rapor_arka_yuz', null);
    } else {
      ((async () => {
        const data = await fileToBase64(engelliSehitGaziKimlikRaporArkaYuzDosya);
        if (data) {
          form.setFieldValue('engelli_sehit_gazi_kimlik_rapor_arka_yuz', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [engelliSehitGaziKimlikRaporArkaYuzDosya]);

  const handleClearEngelliSehitGaziYakinNufusCuzdaniOnYuz = () => {
    setEngelliSehitGaziYakinNufusCuzdaniOnYuzDosya(null);
    form.setFieldValue('engelli_sehit_gazi_nufus_cuzdani_on_yuz', null);
  }

  useEffect(() => {
    if (engelliSehitGaziYakinNufusCuzdaniOnYuzDosya === null) {
      form.setFieldValue('engelli_sehit_gazi_nufus_cuzdani_on_yuz', null);
    } else {
      ((async () => {
        const data = await fileToBase64(engelliSehitGaziYakinNufusCuzdaniOnYuzDosya);
        if (data) {
          form.setFieldValue('engelli_sehit_gazi_nufus_cuzdani_on_yuz', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [engelliSehitGaziYakinNufusCuzdaniOnYuzDosya]);

  const handleClearEngelliSehitGaziYakinNufusCuzdaniArkaYuz = () => {
    setEngelliSehitGaziYakinNufusCuzdaniArkaYuzDosya(null);
    form.setFieldValue('engelli_sehit_gazi_nufus_cuzdani_arka_yuz', null);
  }

  useEffect(() => {
    if (engelliSehitGaziYakinNufusCuzdaniArkaYuzDosya === null) {
      form.setFieldValue('engelli_sehit_gazi_nufus_cuzdani_arka_yuz', null);
    } else {
      ((async () => {
        const data = await fileToBase64(engelliSehitGaziYakinNufusCuzdaniArkaYuzDosya);
        if (data) {
          form.setFieldValue('engelli_sehit_gazi_nufus_cuzdani_arka_yuz', data.toString());
        }
      })());
    }
    /* eslint-disable-next-line */
  }, [engelliSehitGaziYakinNufusCuzdaniArkaYuzDosya]);

  const getInitialData = async () => {
    try {
      const {data} = await ApiClient.sehirler();
      const sehirlerList: SelectOptionType[] = [];
      data.map((sehir: any) => sehirlerList.find((item: SelectOptionType) =>
        item.value === sehir.kod) || (sehirlerList.push({value: sehir.value, label: sehir.text})));
      setSehirler(sehirlerList);
    } catch (e: any) {
      console.log(e, e.response);
      setInitialError(true);
    }

    try {
      const {data} = await ApiClient.vericiIsletmeleri();
      const vericiIsletmecileriList: any[] = [];
      data.map((vericiIsletmeci: any) => vericiIsletmecileriList.find((item: SelectOptionType) =>
        item.value === vericiIsletmeci.kod) || (vericiIsletmecileriList.push({
        value: vericiIsletmeci.id,
        label: vericiIsletmeci.ad
      })));
      setVericiIsletmecileri(vericiIsletmecileriList);
    } catch (e) {
      console.log(e);
      setInitialError(true);
    }
  }

  useEffect(() => {
    (async () => {
      await getInitialData();
    })();
  }, []);

  useEffect(() => {
    if (sehirler.length > 0 && vericiIsletmecileri.length > 0) {
      setInitialized(true);
    }
  }, [sehirler, vericiIsletmecileri]);

  const handleChangeSehir = async (value: string) => {
    setSelectedSehir(sehirler.find(item => item.value === value));
    setKoyler([]);
    setBucaklar([]);
    setMahalleler([]);
    setSelectedIlce(null);
    setSelectedBucak(null);
    setSelectedKoy(null);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('sehir', value);
    form.setFieldValue('ilce', null);
    form.setFieldValue('bucak', null);
    form.setFieldValue('koy', null);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.ilceler(value);
        const ilcelerList: SelectOptionType[] = [];
        data.map((ilce: any) => ilcelerList.find((item: SelectOptionType) =>
          item.value === ilce.kod) || (ilcelerList.push({value: ilce.value, label: ilce.text})));
        setIlceler(ilcelerList);
        ilceRef.current?.focus();
      } catch (e) {
        setSelectedIlce(null);
        setIlceler([]);
        console.log(e);
      } finally {
        form.setFieldValue('ilce', null);
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeIlce = async (value: string) => {
    setSelectedIlce(ilceler.find(item => item.value === value));
    setBucaklar([]);
    setKoyler([]);
    setMahalleler([]);
    setSelectedBucak(null);
    setSelectedKoy(null);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('ilce', value);
    form.setFieldValue('bucak', null);
    form.setFieldValue('koy', null);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.bucaklar(value);
        const bucaklarList: SelectOptionType[] = [];
        data.map((bucak: any) => bucaklarList.find((item: SelectOptionType) =>
          item.value === bucak.kod) || (bucaklarList.push({value: bucak.value, label: bucak.text})));
        if (bucaklarList.length === 1) {
          await handleChangeBucak(bucaklarList[0].value);
          form.setFieldValue('bucak', bucaklarList[0].value);
          setSelectedBucak(bucaklarList[0]);
          setTimeout(() => {
            koyRef.current?.focus();
          }, 100);
        } else {
          form.setFieldValue('bucak', null);
          setTimeout(() => {
            bucakRef.current?.focus();
          }, 100);
        }
        setBucaklar(bucaklarList);
      } catch (e) {
        setSelectedBucak(null);
        setBucaklar([]);
        form.setFieldValue('bucak', null);
        console.log(e);
      } finally {
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeBucak = async (value: string) => {
    setSelectedBucak(bucaklar.find(item => item.value === value));
    setKoyler([]);
    setMahalleler([]);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('bucak', value);
    form.setFieldValue('koy', null);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.koyler(value);
        const koylerList: SelectOptionType[] = [];
        data.map((koy: any) => koylerList.find((item: SelectOptionType) =>
          item.value === koy.value) || (koylerList.push({value: koy.value, label: koy.text})));
        if (koylerList.length === 1) {
          await handleChangeKoy(koylerList[0].value);
          form.setFieldValue('koy', koylerList[0].value);
          setSelectedKoy(koylerList[0]);
          setTimeout(() => {
            mahalleRef.current?.focus();
          }, 100);
        } else {
          setTimeout(() => {
            koyRef.current?.focus();
          }, 100);
        }
        setKoyler(koylerList);
        koyRef.current?.focus();
      } catch (e) {
        form.setFieldValue('koy', null);
        setSelectedKoy(null);
        setKoyler([]);
        console.log(e);
      } finally {
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeKoy = async (value: string) => {
    setSelectedKoy(koyler.find(item => item.value === value));
    setMahalleler([]);
    setSelectedMahalle(null);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('koy', value);
    form.setFieldValue('mahalle', null);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.mahalleler(value);
        const mahallelerList: SelectOptionType[] = [];
        data.map((mahalle: any) => mahallelerList.find((item: SelectOptionType) =>
          item.value === mahalle.kod) || (mahallelerList.push({value: mahalle.value, label: mahalle.text})));
        setMahalleler(mahallelerList);
        mahalleRef.current?.focus();
      } catch (e) {
        setSelectedMahalle(null);
        setMahalleler([]);
        console.log(e);
      } finally {
        form.setFieldValue('mahalle', null);
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeMahalle = async (value: string) => {
    setSelectedMahalle(mahalleler.find(item => item.value === value));
    setSokaklar([]);
    setSelectedSokak(null);
    setSelectedBina(null);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('mahalle', value);
    form.setFieldValue('sokak', null);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.sokaklar(value);
        const sokaklarList: SelectOptionType[] = [];
        data.map((sokak: any) => sokaklarList.find((item: SelectOptionType) =>
          item.value === sokak.kod) || (sokaklarList.push({value: sokak.id, label: sokak.text})));
        setSokaklar(sokaklarList);
        setTimeout(() => {
          sokakRef.current?.focus();
        }, 200);
      } catch (e) {
        setSelectedSokak(null);
        setSokaklar([]);
        console.log(e);
      } finally {
        form.setFieldValue('sokak', null);
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeSokak = async (value: string) => {
    setSelectedSokak(sokaklar.find(item => item.value === value));
    setBinalar([]);
    setSelectedBina(null);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('sokak', value);
    form.setFieldValue('bina', null);
    form.setFieldValue('daire', null);
    form.setFieldValue('tarife', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.binalar(value);

        if (data.length === 0) {
          notifications.show({
            withCloseButton: true,
            autoClose: 2000,
            message: 'Bina listesi boş!',
            color: 'red',
            icon: <IconX/>,
            loading: false,
          });
        }

        const binalarList: SelectOptionType[] = [];
        data.map((bina: any) => binalarList.find((item: SelectOptionType) =>
          item.value === bina.kod) || (binalarList.push({value: bina.id, label: bina.text})));
        setBinalar(binalarList);
        binaRef.current?.focus();
      } catch (e) {
        setSelectedBina(null);
        setBinalar([]);
        console.log(e);
      } finally {
        form.setFieldValue('bina', null);
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeBina = async (value: string | null) => {
    setSelectedBina(binalar.find(item => item.value === value));
    setDaireler([]);
    setSelectedDaire(null);
    setTarifeTipi(null);
    form.setFieldValue('bina', value);
    form.setFieldValue('daire', null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        const {data} = await ApiClient.daireler(value);
        const dairelerList: SelectOptionType[] = [];

        if (data.length === 0) {
          notifications.show({
            withCloseButton: true,
            autoClose: 2000,
            message: 'Daire listesi boş!',
            color: 'red',
            icon: <IconX/>,
            loading: false,
          });
        }

        if (data.length === 0) {
          dairelerList.push({value: value, label: 'Boş'});
        } else {
          if (Array.isArray(data)) {
            data.map((daire: any) => dairelerList.find((item: SelectOptionType) =>
              item.value === daire.kod) || (dairelerList.push({value: daire.bbk, label: daire.door_number})));
          } else {
            dairelerList.push({value: data.self.bbk, label: data.self.door_number});
          }
        }
        setDaireler(dairelerList);
        daireRef.current?.focus();
      } catch (e) {
        setSelectedDaire(null);
        setDaireler([]);
        console.log(e);
      } finally {
        form.setFieldValue('daire', null);
        setAdresSorgu(false);
      }
    }
  }

  const handleChangeDaire = async (value: string | null) => {
    setSelectedDaire(daireler.find(item => item.value === value));
    form.setFieldValue('daire', value);
    setAdres(null);
    setBbkKod(null);
    setAdres(null);
    setUavt(null);
    setTarifeTipi(null);

    if (value !== null) {
      try {
        setAdresSorgu(true);
        setIsAltyapiSorgu(true);
        const adresKoduResponse = await ApiClient.adresKodu(value);
        setAdres(adresKoduResponse.data.adres_metin);
        setUavt(adresKoduResponse.data.uavt);
        setBbkKod(adresKoduResponse.data.bbk);

        if (form.getInputProps('basvuru_tipi').value === '1') {
          const controlService = adresKoduResponse.data['control_service'];
          const services = {
            dsl: {
              service: false,
              port: false,
              speed: 0
            },
            vdsl: {
              service: false,
              port: false,
              speed: 0
            },
            fiber: {
              service: false,
              port: false,
              speed: 0
            }
          };

          if (controlService['adsl'].find !== undefined) {
            const service = controlService['adsl'].find((i: any) => i.name === "SNTRLHZMT") === undefined ?
              0 : controlService['adsl'].find((i: any) => i.name === "SNTRLHZMT")['value'];
            const port = controlService['adsl'].find((i: any) => i.name === "BSPRT") === undefined ?
              0 : controlService['adsl'].find((i: any) => i.name === "BSPRT")['value']
            const speed = controlService['adsl'].find((i: any) => i.name === "NMSMAX") === undefined ?
              0 : parseInt(controlService['adsl'].find((i: any) => i.name === "NMSMAX")['value']);

            services.dsl = {
              service: service === '1',
              port: service === '1' && port === '1',
              speed: speed > 0 ? speed / 1000 : 0
            }
          }

          if (controlService['vdsl'].find !== undefined) {
            const service = controlService['vdsl'].find((i: any) => i.name === "SNTRLHZMT") === undefined ?
              0 : controlService['vdsl'].find((i: any) => i.name === "SNTRLHZMT")['value'];
            const port = controlService['vdsl'].find((i: any) => i.name === "BSPRT") === undefined ?
              0 : controlService['vdsl'].find((i: any) => i.name === "BSPRT")['value']
            const speed = controlService['vdsl'].find((i: any) => i.name === "NMSMAX") === undefined ?
              0 : parseInt(controlService['vdsl'].find((i: any) => i.name === "NMSMAX")['value']);

            services.vdsl = {
              service: service === '1',
              port: service === '1' && port === '1',
              speed: speed > 0 ? speed / 1000 : 0
            }
          }

          if (controlService['fttxv1'].find !== undefined) {
            const service = controlService['fttxv1'].find((i: any) => i.name === "SNTRLHZMT") === undefined ?
              0 : controlService['fttxv1'].find((i: any) => i.name === "SNTRLHZMT")['value'];
            const port = controlService['fttxv1'].find((i: any) => i.name === "BSPRT") === undefined ?
              0 : controlService['fttxv1'].find((i: any) => i.name === "BSPRT")['value']

            let speedStatus = controlService['fttxv1'].find((i: any) => i.name === "FTTX1GB") === undefined ?
              '0' : controlService['fttxv1'].find((i: any) => i.name === "FTTX1GB")['value'];
            let speed = 0;
            if (speedStatus === '0' || speedStatus === '1') {
              speed = 1000;
            } else {
              speed = 100;
            }

            services.fiber = {
              service: service === '1',
              port: service === '1' && port === '1',
              speed: speed
            }
          }

          if (services.fiber.service) {
            setTarifeTipi('fiber');
            setPortDurum(services.fiber.port);
            setMaxHiz(Math.floor(services.fiber.speed));
          } else if (services.vdsl.service) {
            setTarifeTipi('dsl');
            setDslTarifeTipi('vdsl');
            setPortDurum(services.vdsl.port);
            setMaxHiz(Math.floor(services.vdsl.speed));
          } else if (services.dsl.service) {
            setTarifeTipi('dsl');
            setDslTarifeTipi('adsl');
            setPortDurum(services.dsl.port);
            setMaxHiz(services.dsl.speed);
          } else {
            setTarifeTipi('yok');
          }
        } else {
          if (form.getInputProps('xdsl_hizmet_numarasi').value.toString().startsWith('179')) {
            setTarifeTipi('fiber')
          } else {
            setTarifeTipi('dsl')
          }
        }

        setTimeout(() => {
          tarifeRef.current?.focus();
        }, 100);
      } catch (e) {
        console.log(e);
      } finally {
        setAdresSorgu(false);
        setIsAltyapiSorgu(false);
      }
    }
  }

  useEffect(() => {
    if (tarifeTipi === null || tarifeTipi === 'yok') {
      setTarifeler([]);
    } else {
      (async () => {
        try {
          const {data} = await ApiClient.tarifeler(tarifeTipi!);
          const tarifelerList: SelectOptionType[] = [];
          data.map((tarife: any) => tarifelerList.find((item: SelectOptionType) =>
            item.value === tarife.kod) || (tarifelerList.push({value: tarife.id, label: tarife.ad})));
          setTarifeler(tarifelerList);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [tarifeTipi])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    form.validate();
    if (!form.isValid()) {
      console.log(form.errors);
      console.log(form.values);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        title: "Hata!",
        message: 'Formda eksik ya da hatalı alanlar var.',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      return;
    }

    setIsLoading(true);
    if (!await handleIdentityVerification()) {
      notifications.show({
        withCloseButton: true,
        autoClose: 5000,
        title: "Hata!",
        message: 'Kimlik doğrulama başarısız oldu. Lütfen bilgilerinizi kontrol ediniz.',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
      setIdentityVerified(false);
      kisiselBilgilerRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
      setIsLoading(false);
      return;
    }
    setIdentityVerified(true);

    if (dealer !== null || isGsmVerified) {
      await onSubmit();
      return;
    }

    setIsLoading(false);
    setGsmFormModalOpened(true);
  }

  const handleIdentityVerification = async () => {
    try {
      /*
      await ApiClient.kimlikDogrulama({
        tc_kimlik_no: form.getInputProps('tc_kimlik_no').value,
        ad: form.getInputProps('ad').value,
        soyad: form.getInputProps('soyad').value,
        dogum_tarihi: form.getInputProps('dogum_tarihi').value,
      });
      */
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  const onSubmit = async () => {
    setIsGsmVerified(true);
    const sehirOption = sehirler.filter((sehir: SelectOptionType) =>
      sehir.value === form.getInputProps('sehir').value);
    const ilceOption = ilceler.filter((ilce: SelectOptionType) =>
      ilce.value === form.getInputProps('ilce').value);
    const bucakOption = bucaklar.filter((bucak: SelectOptionType) =>
      bucak.value === form.getInputProps('bucak').value);
    const koyOption = koyler.filter((koy: SelectOptionType) =>
      koy.value === form.getInputProps('koy').value);
    const mahalleOption = mahalleler.filter((mahalle: SelectOptionType) =>
      mahalle.value === form.getInputProps('mahalle').value);
    const sokakOption = sokaklar.filter((sokak: SelectOptionType) =>
      sokak.value === form.getInputProps('sokak').value);
    const binaOption = binalar.filter((bina: SelectOptionType) =>
      bina.value === form.getInputProps('bina').value);
    const daireOption = daireler.filter((daire: SelectOptionType) =>
      daire.value === form.getInputProps('daire').value);

    const formData = {
      ...form.values,
      oturum_id: session?.id,
      telefon: phoneFormat(form.getInputProps('telefon').value),
      sehir_ad: sehirOption.length > 0 ? sehirOption[0].label : null,
      ilce_ad: ilceOption.length > 0 ? ilceOption[0].label : null,
      bucak_ad: bucakOption.length > 0 ? bucakOption[0].label : null,
      koy_ad: koyOption.length > 0 ? koyOption[0].label : null,
      mahalle_ad: mahalleOption.length > 0 ? mahalleOption[0].label : null,
      sokak_ad: sokakOption.length > 0 ? sokakOption[0].label : null,
      bina_ad: binaOption.length > 0 ? binaOption[0].label : null,
      daire_ad: daireOption.length > 0 ? daireOption[0].label : null,
      adres,
      uavt,
      bbk_kodu: bbkKod,
      tarife_tipi: tarifeTipi
    }

    try {
      setIsLoading(true);
      const {data} = await ApiClient.onlineBasvuru(formData);
      setCompletedAt(data.date);
    } catch (e: any) {
      console.log(e);
      let message = 'Başvuru sırasında bir hata oluştu. Lütfen tekrar deneyiniz.';
      if (e.response.status === 400) {
        message = e.response.data.message;
      }
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        title: "Hata!",
        message,
        color: 'red',
        icon: <IconX/>,
        loading: false,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeXDslNumber = () => {
    form.setFieldValue('daire', null);
    form.setFieldValue('adres', null);
    form.setFieldValue('tarife', null);
    setTarifeler([]);
    setTarifeTipi(null);
  }

  const handleBlurXDslNumber = async () => {
    if (!form.getInputProps('xdsl_hizmet_numarasi').value) {
      setXdslResult(null);
    }
    if (form.getInputProps('basvuru_tipi').value === '2' &&
      form.getInputProps('xdsl_hizmet_numarasi').value) {
      try {
        setXdslSorgu(true);
        setXdslResult(null);
        const {data} = await ApiClient.xdslHizmetNumarasiDogrulama(form.getInputProps('xdsl_hizmet_numarasi').value);
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          message: data.success,
          color: 'green',
          icon: <IconCheck/>,
          loading: false,
        });
        setXdslResult({
          status: 'success',
          icon: <IconCheck/>,
          color: 'green',
          message: data.success,
          company: data.company,
        });
        form.setFieldValue('firma', data.company.id);
      } catch (e: any) {
        const message = e.response.data.error;
        if (e.response.status === 400) {
          notifications.show({
            withCloseButton: true,
            autoClose: 4000,
            title: 'Uyarı',
            message: e.response.data.error,
            color: 'orange',
            icon: <IconAlertCircle/>,
            loading: false,
          });
          setXdslResult({
            status: 'success',
            icon: <IconAlertCircle/>,
            color: 'yellow',
            message: e.response.data.error,
            company: e.response.data.company,
          })
          form.setFieldValue('firma', e.response.data.company.id);
          return;
        }

        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: 'Hata!',
          message: message,
          color: 'red',
          icon: <IconX/>,
          loading: false,
        });
        setXdslResult({
          status: 'error',
          icon: <IconX/>,
          color: 'red',
          message: message,
        });

        form.setFieldError('xdsl_hizmet_numarasi', e.response.data.error);
        console.log(e);
      } finally {
        setXdslSorgu(false);
      }
    }
  }

  const handleRefresh = async () => {
    await getInitialData();
  }

  const handleGoHome = () => {
    window.location.href = 'https://www.pananet.com.tr';
  }

  const handleGoNew = () => {
    window.location.reload();
  }

  useEffect(() => {
    if (completedAt !== null) {
      (async () => {
        try {
          let ad: string;
          let soyad: string;
          if (form.getInputProps('abonelik_tipi').value === '2') {
            ad = form.getInputProps('yetkili_adi').value;
            soyad = form.getInputProps('yetkili_soyadi').value;
          } else {
            ad = form.getInputProps('ad').value;
            soyad = form.getInputProps('soyad').value;
          }
          let tarife = '';
          if (form.getInputProps('tarife').value) {
            tarife = tarifeler.filter((tarife: SelectOptionType) =>
              tarife.value === form.getInputProps('tarife').value)[0].label;
          }
          const telefon = phoneFormat(form.getInputProps('telefon').value);
          await axios.get(`https://api.telegram.org/bot5753425503:AAGIP8NL-3cCs-EjXoLUPxw3LXU25WeMepI/sendmessage?chat_id=449205396&text=Adı_Soyadı=${ad} ${soyad} Telefon=${telefon} Adres=${adres} Email=${form.getInputProps('eposta').value} Tariff=${tarife} InternetType=${tarifeTipi} Speed=${maxHiz} Date=${completedAt}`);
        } catch (e: any) {
          console.log(e);
        }
      })();
    }
  }, [adres, completedAt, form, maxHiz, tarifeTipi, tarifeler]);

  const handleChangeAbonelikTipi = (value: any) => {
    form.setFieldValue('abonelik_tipi', value);
    if (value === '2') {
      setEngelliIndirim(false);
      form.setFieldValue('engelli_sehit_gazi_indirim', false);
      form.setFieldValue('engelli_sehit_gazi_yakin', null);
    }
  }

  const handleChangeEngelliSehitGaziIndirim = (e: any) => {
    if (e.target.checked) {
      form.setFieldValue('engelli_sehit_gazi_indirim', true);
      setEngelliIndirim(true);
    } else {
      form.setFieldValue('engelli_sehit_gazi_indirim', false);
      form.setFieldValue('engelli_sehit_gazi_yakin', null);
      setEngelliIndirim(false);
    }
  }

  const handleLogout = () => {
    setIsLoading(true);
    localStorage.removeItem('token');
    setDealer(null);
    setIsLoading(false);
  }

  useEffect(() => {
    console.log('Port:', portDurum);
  }, [portDurum]);

  return (
    <Box>
      {(!initialized || session === null) ? (
        <>
          {initialError ? (
            <Alert icon={<IconAlertCircle size="1rem"/>} color="red">
              <Flex justify="center" align="center" direction="column" wrap="wrap" gap="md">
                <Text size="md">Başvuru formu yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.</Text>
                <Button leftIcon={<IconRefresh size={14}/>}
                        color="green"
                        onClick={handleRefresh}>Yeniden dene</Button>
              </Flex>
            </Alert>
          ) : (
            <Flex align="center" justify="center">
              <Loader variant="bars"/>
            </Flex>
          )}
        </>
      ) : (
        <>
          {completedAt !== null ? (
            <Box m="xl">
              <Alert color="green" mb="md">
                <Text size="xl" weight="bold" align="center" color="green">Başvurunuz başarı ile oluşturulmuştur.</Text>
                <Text size="md" mt="md">
                  {form.getInputProps('basvuru_tipi').value === '1' ? (
                      <ul>
                          <li>Başvurunuzu E-Devlet üzerinden onaylamanız gerekmektedir.</li>

                          <li><a href="https://www.pananet.com.tr/edevletonay.html" target="_blank">
                              <img
                                  src="img/edevlet.png"
                                  alt="E-Devlet"
                                  style={{width: '30px', height: '30px', marginRight: '10px'}}
                              />
                              <strong>E-Devlet üzerinden başvuru onayı ve bilgilendirme için tıklayınız.</strong>
                          </a></li>
                          <li>E-Devlet Onay sonrasında 7 iş günü
                              içerisinde ekipler bağlantı işlemlerini yapacaktır.
                          </li>
                          <li>İlk kurulumda <strong>{NEW_CONNECTION_COST}TL bağlantı ücreti</strong> olmaktadır ve ilk
                              faturanıza
                              yansıtılmaktadır.
                          </li>

                      </ul>
                  ) : (
                      <>
                          {tarifeTipi === 'dsl' ? (
                              <ul>
                                  <li>Başvurunuzu E-Devlet üzerinden onaylamanız gerekmektedir.</li>

                                  <li><a href="https://www.pananet.com.tr/edevletonay.html" target="_blank">
                                      <img
                                          src="img/edevlet.png"
                                          alt="E-Devlet"
                                          style={{width: '30px', height: '30px', marginRight: '10px'}}
                                      />
                                      <strong>E-Devlet üzerinden başvuru onayı ve bilgilendirme için tıklayınız.</strong>
                                  </a></li>
                                  <li>E-Devlet üzerinden başvuru onayı sonrasında geçiş işlemleriniz başlatılarak
                                  </li>
                                  <li>48 saat içerisinde(bu süre mevcut firmanızın başvuru onay hızına göre
                                      değişmektedir)
                                      geçiş işleminiz tamamlanarak ayrıca modem kullanıcı bilgileriniz SMS ile
                                      iletilecekir.
                                  </li>
                                  <li>Geçiş işleminizin tamamlandığına
                                      dair sms iletilmesinden sonra size iletilen yeni modem kullanıcı bilgilerinizi
                                      modeminizden
                                      güncelleyerek kullanım sağlayabilirsiniz. Güncelleme yapmayı bilmiyorsanız 0850
                                      304 72 62
                                      WhatsApp Destek hattından destek alabilirsiniz.
                                  </li>

                              </ul>
                          ) : (
                              <ul>


                                  <li>
                                      <a href="https://www.pananet.com.tr/edevletonay.html" target="_blank">
                                          <img
                                              src="img/edevlet.png"
                                              alt="E-Devlet"
                                              style={{width: '30px', height: '30px', marginRight: '10px'}}
                                          />
                                          <strong>E-Devlet üzerinden başvuru onayı ve bilgilendirme için
                                              tıklayınız.</strong>
                                      </a>
                                  </li>
                                  <li>E-Devlet üzerinden başvuru onayı sonrasında geçiş işlemleriniz başlatılarak
                                  </li>
                                  <li>48 saat içerisinde(bu süre mevcut firmanızın başvuru onay hızına göre
                                      değişmektedir)
                                      geçiş işleminiz tamamlanarak ayrıca modem kullanıcı bilgileriniz SMS ile
                                      iletilecekir.
                                  </li>
                                  <li>Geçiş işleminizin tamamlandığına
                                      dair sms iletilmesinden sonra size iletilen yeni modem kullanıcı bilgilerinizi
                                      modeminizden
                                      güncelleyerek kullanım sağlayabilirsiniz. Güncelleme yapmayı bilmiyorsanız 0850
                                      304 72 62
                                      WhatsApp Destek hattından destek alabilirsiniz.
                                  </li>
                              </ul>
                          )}
                      </>
                  )}
                </Text>
                  <Text size="md" mt="md" align="center" color="green">PanaNet'i tercih ettiğiniz için teşekkür
                      ederiz.</Text>
              </Alert>
                <Flex align="center" justify="center">
                    <Button leftIcon={<IconHome size={14}/>} mr="xs" onClick={handleGoHome}>Anasayfa</Button>
                    <Button leftIcon={<IconRefresh size={14}/>} color="green" onClick={handleGoNew}>Yeni
                        başvuru</Button>
                </Flex>
            </Box>
          ) : (
              <>
                  {dealer !== null && (
                      <Flex align="center" justify="flex-end" wrap="wrap" gap="md">
                          <Text size="md" weight="bold">Bayi: {dealer.username}</Text>
                          <Button leftIcon={<IconLogout size={14}/>}
                                  mr="xs"
                                  color="red"
                                  onClick={handleLogout}>Çıkış yap</Button>
                      </Flex>
                  )}
                  <SignatureModal show={signModalOpened}
                                  onClose={() => setSignModalOpened(false)}
                                  onSigned={signature => form.setFieldValue('imza', signature)}/>
                  <GsmCheckFormModal show={gsmFormModalOpened}
                                     oturumId={session.id}
                                     phone={phoneFormat(form.getInputProps('telefon').value)}
                                 onVerified={async () => await onSubmit()}
                                 onClose={() => setGsmFormModalOpened(false)}/>
              <form onSubmit={handleSubmit}>
                <LoadingOverlay visible={isLoading} overlayBlur={2}/>
                <fieldset className={classes.fieldset}>
                  <legend>Abone başvuru bilgileri</legend>
                  <Grid mb="md">
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Radio.Group label="Başvuru tipi"
                                   withAsterisk
                                   {...form.getInputProps('basvuru_tipi')}>
                        <Group my="xs">
                          <Radio label="Farklı operatörden ücretsiz geçiş" value="2"/>
                          <Radio label="Yeni abone" value="1"/>
                        </Group>
                      </Radio.Group>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Radio.Group label="Sabit telefonunuz var mı?"
                                   withAsterisk
                                   {...form.getInputProps('sabit_telefon_var_mi')}>
                        <Group my="xs">
                          <Radio label="Hayır" value="0"/>
                          <Radio label="Evet" value="1"/>
                        </Group>
                      </Radio.Group>
                    </Grid.Col>
                    {form.getInputProps('sabit_telefon_var_mi').value === '1' && (
                      <Grid.Col xs={12} sm={6} md={4}>
                        <Input.Wrapper label="Sabit telefon numarası"
                                       withAsterisk
                                       {...form.getInputProps('sabit_telefon')}>
                          <Flex align="center" justify="flex-start" className={classes.phoneInput}>
                            <Box className="prefix">+90</Box>
                            <Input placeholder="Sabit telefon numarası giriniz"
                                   component={IMaskInput}
                                   mask="(000) 000 00 00"
                                   unmask={'typed'}
                                   autoFocus
                                   data-autofocus
                                   {...form.getInputProps('sabit_telefon')}/>
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                  </Grid>
                  {form.getInputProps('basvuru_tipi').value === '1' && (
                    <Alert icon={<IconAlertCircle size="1rem"/>} title="Uyarı!" color="orange">
                      Yeni abonelikte <strong>{NEW_CONNECTION_COST}TL</strong> evde kurulum ücreti olmaktadır ve ilk
                      faturanıza yansıtılmaktadır.
                    </Alert>
                  )}
                  {form.getInputProps('basvuru_tipi').value === '2' && (
                    <>
                      <Grid>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <Input.Wrapper label="xDSL hizmet numarası"
                                         withAsterisk
                                         {...form.getInputProps('xdsl_hizmet_numarasi')}
                                         onBlur={handleBlurXDslNumber}
                                         onChange={handleChangeXDslNumber}>
                            <Input placeholder="xDSL hizmet numarası"
                                   component={IMaskInput}
                                   mask="0000000000"
                                   autoFocus
                                   data-autofocus
                                   disabled={xdslSorgu}
                                   {...form.getInputProps('xdsl_hizmet_numarasi')}/>
                          </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <Flex align="start" justify="start" direction="column">
                            <>
                              {xdslSorgu && (
                                <Loader size="sm" style={{marginTop: 30}}/>
                              )}
                              {!xdslSorgu && xdslResult && xdslResult.company && (
                                <>
                                  <Text size="sm" style={{marginBottom: 8}}>Mevcut Firma:</Text>
                                  <Text>{xdslResult.company.name}</Text>
                                </>
                              )}
                              <Input type="hidden" {...form.getInputProps('firma')}/>
                            </>
                          </Flex>
                        </Grid.Col>
                      </Grid>
                      {xdslResult !== null && (
                        <Flex align="center" justify="start">
                          <Alert icon={xdslResult.icon}
                                 color={xdslResult.color}
                                 mt="md"
                                 style={{width: '100%'}}>{xdslResult.message}</Alert>
                        </Flex>
                      )}
                      <Flex align="center" justify="start">
                        <Alert icon={<IconAlertCircle size="1rem"/>}
                               title="Uyarı!"
                               color="orange"
                               mt="md"
                               style={{width: '100%'}}>
                          xDSL hizmet numaranız 18, 88, 178 ya da 179'la başlayan 10 haneli devre hizmet numaranızdır.
                          Abone numaranız değildir. Bu numara faturanızda ya da online işlemlerde yer almıyorsa mevcut
                          işletmeciniz ile iletişime geçerek öğrenebilirsiniz. Hizmet numaranız yanlış olması durumunda
                          geçiş işleminiz tamamlanamayacaktır.
                        </Alert>
                      </Flex>
                    </>
                  )}
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <legend>Adres bilgiler</legend>
                  <Grid style={{position: 'relative'}}>
                    <LoadingOverlay visible={adresSorgu} overlayBlur={2}/>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Select label="Şehir"
                              placeholder="Şehir seçiniz"
                              data={sehirler}
                              disabled={
                                !form.getInputProps('basvuru_tipi').value ||
                                !form.getInputProps('sabit_telefon_var_mi').value ||
                                (
                                  form.getInputProps('sabit_telefon_var_mi').value === '1' &&
                                  !form.getInputProps('sabit_telefon').value
                                ) ||
                                (
                                  form.getInputProps('basvuru_tipi').value === '2' &&
                                  (
                                    !form.getInputProps('xdsl_hizmet_numarasi').value ||
                                    !form.getInputProps('firma').value ||
                                    (
                                      xdslResult !== null && xdslResult.status === 'error'
                                    )
                                  )
                                )
                              }
                              withAsterisk
                              searchable
                              clearable
                              {...form.getInputProps('sehir')}
                              onChange={handleChangeSehir}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Select label="İlçe"
                              placeholder="İlçe seçiniz"
                              ref={ilceRef}
                              data={ilceler}
                              disabled={!selectedSehir}
                              withAsterisk
                              searchable
                              clearable
                              {...form.getInputProps('ilce')}
                              onChange={handleChangeIlce}/>
                    </Grid.Col>
                    {bucaklar.length > 1 && (
                      <Grid.Col xs={12} sm={6} md={4}>
                        <Select label="Bucak"
                                placeholder="Bucak seçiniz"
                                ref={bucakRef}
                                data={bucaklar}
                                disabled={!selectedIlce}
                                withAsterisk
                                searchable
                                clearable
                                {...form.getInputProps('bucak')}
                                onChange={handleChangeBucak}/>
                      </Grid.Col>
                    )}
                    {koyler.length > 1 && (
                      <Grid.Col xs={12} sm={6} md={4}>
                        <Select label="Köy"
                                placeholder="Köy seçiniz"
                                ref={koyRef}
                                data={koyler}
                                disabled={!selectedBucak}
                                withAsterisk
                                searchable
                                clearable
                                {...form.getInputProps('koy')}
                                onChange={handleChangeKoy}/>
                      </Grid.Col>
                    )}
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Select label="Mahalle"
                              placeholder="Mahalle seçiniz"
                              ref={mahalleRef}
                              data={mahalleler}
                              disabled={!selectedKoy}
                              withAsterisk
                              searchable
                              clearable
                              {...form.getInputProps('mahalle')}
                              onChange={handleChangeMahalle}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Select label="Sokak"
                              placeholder="Sokak seçiniz"
                              ref={sokakRef}
                              data={sokaklar}
                              disabled={!selectedMahalle}
                              withAsterisk
                              searchable
                              clearable
                              {...form.getInputProps('sokak')}
                              onChange={handleChangeSokak}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Select label="Apartman/Bina no"
                              placeholder="Apartman/Bina no seçiniz"
                              ref={binaRef}
                              data={binalar}
                              disabled={!selectedSokak}
                              withAsterisk
                              searchable
                              clearable
                              {...form.getInputProps('bina')}
                              onChange={handleChangeBina}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Select label="Daire/Kapı no"
                              placeholder="Daire/Kapı no seçiniz"
                              ref={daireRef}
                              data={daireler}
                              disabled={!selectedBina}
                              withAsterisk
                              searchable
                              clearable
                              {...form.getInputProps('daire')}
                              onChange={handleChangeDaire}/>
                    </Grid.Col>
                  </Grid>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <legend>Tarife bilgileri</legend>
                  <>
                    {isAltyapiSorgu ? (
                      <Flex align="center" justify="start" className={classes.altyapiSorgu}>
                        <Loader variant="bars" size="xs" mr="xs"/>
                        <div>Altyapı sorgulanıyor...</div>
                      </Flex>
                    ) : (
                      <>
                        {tarifeTipi === null ? (
                          <Flex align="center" justify="start" className={classes.altyapiSorgu}>
                            <Box mr="xs">
                              <IconAlertTriangle size={12}/>
                            </Box>
                            <div>Önce adres bilgilerinizi doldurmalısınız.</div>
                          </Flex>
                        ) : (
                          <>
                            {tarifeTipi === 'yok' ? (
                              <Alert color="red" mb="xs">
                                <Flex align="center" justify="start">
                                  <Box mr="xs" mt="1">
                                    <IconAlertTriangle size={18} color="red"/>
                                  </Box>
                                  <Text size="lg" color="red">Altyapı uygun değil!</Text>
                                </Flex>
                              </Alert>
                            ) : (
                              <>
                                {form.getInputProps('basvuru_tipi').value === '1' && (
                                  <Alert color="green" mb="xs">
                                    <Text size="md">
                                      {tarifeTipi === 'dsl' ? (
                                        <strong>{dslTarifeTipi === 'adsl' ? ' ADSL' : ' VDSL'}</strong>
                                      ) : 'Fiber'} altyapınızın desteklediği yaklaşık maksimum
                                      hız: <strong>{maxHiz}</strong> Mbps
                                    </Text>
                                  </Alert>
                                )}
                                <Grid>
                                  <Grid.Col xs={12} sm={6}>
                                    <Select label="Tarife"
                                            placeholder="Tarife seçiniz"
                                            ref={tarifeRef}
                                            data={tarifeler}
                                            disabled={!selectedDaire || isAltyapiSorgu}
                                            withAsterisk
                                            searchable
                                            clearable
                                            {...form.getInputProps('tarife')}/>
                                  </Grid.Col>
                                  {(tarifeTipi === 'dsl' && form.getInputProps('basvuru_tipi').value === '1') && (
                                    <Grid.Col xs={12} sm={6}>
                                      <Radio.Group label="Adsl-Vdsl modeminiz var mı?"
                                                   withAsterisk
                                                   {...form.getInputProps('modem')}>
                                        <Group my="xs">
                                          <Radio label="Evet" value="1"/>
                                          <Radio label="Hayır" value="0"/>
                                        </Group>
                                      </Radio.Group>
                                    </Grid.Col>
                                  )}
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <legend ref={kisiselBilgilerRef}>Kişisel bilgiler</legend>
                  {!identityVerified && (
                    <Alert variant="light"
                           color="red"
                           mb="md"
                           title="Kimlik bilgileriniz hatalı!"
                           icon={<IconAlertCircle/>}>
                      Ad, Soyad, Doğum tarihi ve TC kimlik numaranızı kontrol ediniz.
                    </Alert>
                  )}
                  <Grid>
                    <Grid.Col span={12}>
                      <Radio.Group label="Abonelik tipi"
                                   withAsterisk
                                   {...form.getInputProps('abonelik_tipi')}
                                   onChange={handleChangeAbonelikTipi}>
                        <Group my="xs">
                          <Radio label="Bireysel" value="1"/>
                          <Radio label="Kurumsal" value="2"/>
                        </Group>
                      </Radio.Group>
                    </Grid.Col>
                    {form.getInputProps('abonelik_tipi').value === '1' && (
                      <Grid.Col xs={12} sm={6} md={4}>
                        <TextInput label="İsim"
                                   placeholder="İsim"
                                   withAsterisk
                                   {...form.getInputProps('ad')}/>
                      </Grid.Col>
                    )}
                    {form.getInputProps('abonelik_tipi').value === '1' && (
                      <Grid.Col xs={12} sm={6} md={4}>
                        <TextInput label="Soyisim"
                                   placeholder="Soyisim"
                                   withAsterisk
                                   {...form.getInputProps('soyad')}/>
                      </Grid.Col>
                    )}
                    {form.getInputProps('abonelik_tipi').value === '2' && (
                      <>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <TextInput label="Firma/Kurum ünvanı"
                                     placeholder="Firma/Kurum ünvanı giriniz"
                                     withAsterisk
                                     {...form.getInputProps('firma_unvani')}/>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <TextInput label="Vergi dairesi"
                                     placeholder="Vergi dairesi"
                                     withAsterisk
                                     {...form.getInputProps('vergi_dairesi')}/>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <Input.Wrapper label="Vergi numarası"
                                         withAsterisk
                                         {...form.getInputProps('vergi_numarasi')}>
                            <Input placeholder="Vergi numarası yazınız"
                                   component={IMaskInput}
                                   mask="0000000000"
                                   {...form.getInputProps('vergi_numarasi')}/>
                          </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <TextInput label="Yetkili adı"
                                     placeholder="Yetkili adı"
                                     withAsterisk
                                     {...form.getInputProps('yetkili_adi')}/>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} md={4}>
                          <TextInput label="Yetkili soyadı"
                                     placeholder="Yetkili soyadı"
                                     withAsterisk
                                     {...form.getInputProps('yetkili_soyadi')}/>
                        </Grid.Col>
                      </>
                    )}
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Input.Wrapper label="TC kimlik no"
                                     withAsterisk
                                     {...form.getInputProps('tc_kimlik_no')}>
                        <Input placeholder="TC kimlik no yazınız"
                               component={IMaskInput}
                               mask="00000000000"
                               {...form.getInputProps('tc_kimlik_no')}/>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <TextInput label="Kimlik seri no"
                                 placeholder="Kimlik seri no"
                                 withAsterisk
                                 {...form.getInputProps('kimlik_seri_no')}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <TextInput label="Anne adı"
                                 placeholder="Anne adı"
                                 withAsterisk
                                 {...form.getInputProps('anne_adi')}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <TextInput label="Baba adı"
                                 placeholder="Baba adı"
                                 withAsterisk
                                 {...form.getInputProps('baba_adi')}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Input.Wrapper label="Doğum tarihi"
                                     withAsterisk
                                     {...form.getInputProps('dogum_tarihi')}>
                        <Input placeholder="Doğum tarihi giriniz"
                               component={IMaskInput}
                               mask="00.00.0000"
                               {...form.getInputProps('dogum_tarihi')}/>
                      </Input.Wrapper>
                    </Grid.Col>
                    {form.getInputProps('abonelik_tipi').value === '1' && (
                      <Grid.Col xs={12} sm={6} md={4}>
                        <Select label="Cinsiyet"
                                placeholder="Cinsiyet seçiniz"
                                data={[
                                  {value: 'erkek', label: 'Erkek'},
                                  {value: 'kadin', label: 'Kadın'}
                                ]}
                                clearable
                                withAsterisk
                                {...form.getInputProps('cinsiyet')}/>
                      </Grid.Col>
                    )}
                    <Grid.Col xs={12} sm={6} md={4}>
                      <TextInput label="E-posta"
                                 placeholder="E-posta"
                                 withAsterisk
                                 {...form.getInputProps('eposta')}/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4}>
                      <Input.Wrapper label="Cep Telefonu"
                                     withAsterisk
                                     {...form.getInputProps('telefon')}>
                        <Flex align="center" justify="flex-start" className={classes.phoneInput}>
                          <Box className="prefix">+90</Box>
                          <Input placeholder="Cep telefonu numarası giriniz"
                                 component={IMaskInput}
                                 mask="(000) 000 00 00"
                                 unmask={'typed'}
                                 {...form.getInputProps('telefon')}/>
                        </Flex>
                      </Input.Wrapper>
                    </Grid.Col>
                    {form.getInputProps('abonelik_tipi').value === '1' && (
                      <>
                        <Grid.Col span={12}>
                          <Checkbox label="Engelli/Şehit/Gazi indiriminden yararlanmak istiyorum"
                                    {...form.getInputProps('engelli_sehit_gazi_indirimi', {type: 'checkbox'})}
                                    checked={engelliIndirim}
                                    onChange={handleChangeEngelliSehitGaziIndirim}/>
                        </Grid.Col>
                        {engelliIndirim && (
                          <Grid.Col span={12}>
                            <Radio.Group label="Engelli/Şehit/Gazi yakınlığı"
                                         withAsterisk
                                         {...form.getInputProps('engelli_sehit_gazi_yakin')}>
                              <Group my="xs">
                                <Radio label="Kendim" value="1"/>
                                <Radio label="1. derece yakınım" value="2"/>
                              </Group>
                            </Radio.Group>
                          </Grid.Col>
                        )}
                      </>
                    )}
                  </Grid>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <legend>Gerekli evraklar</legend>
                  <Grid>
                    <Grid.Col xs={12} sm={6} lg={4}>
                      <Input.Wrapper
                        label={`${form.getInputProps('abonelik_tipi').value === '2' ? 'Abone yetkili kimlik' : 'Abone kimlik'} ön yüzü`}
                        description="Abone kimlik ön yüzünün resmini yükleyin"
                        withAsterisk
                        {...form.getInputProps('kimlik_on_yuz')}>
                        {form.getInputProps('kimlik_on_yuz').value && (
                          <Image mx="auto" radius="md" src={form.getInputProps('kimlik_on_yuz').value} alt="" mb="md"/>
                        )}
                        <Flex my="xs" align="center" justify="start">
                          {form.getInputProps('kimlik_on_yuz').value ? (
                            <Button leftIcon={<IconTrash size={14}/>}
                                    type="button"
                                    color="red"
                                    onClick={handleClearKimlikOnYuz}>Temizle</Button>
                          ) : (
                            <FileButton onChange={file => setKimlikOnYuzDosya(file)}
                                        accept="image/png,image/jpeg,image/jpg">
                              {(props) => {
                                return <Button leftIcon={<IconFile size={14}/>}
                                               color="green"
                                               {...props}>Dosya seç</Button>
                              }}
                            </FileButton>
                          )}
                        </Flex>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} lg={4}>
                      <Input.Wrapper
                        label={`${form.getInputProps('abonelik_tipi').value === '2' ? 'Abone yetkili kimlik' : 'Abone kimlik'} arka yüzü`}
                        description="Abone kimlik arka yüzünün resmini yükleyin"
                        withAsterisk
                        {...form.getInputProps('kimlik_arka_yuz')}>
                        {form.getInputProps('kimlik_arka_yuz').value && (
                          <Image mx="auto" radius="md" src={form.getInputProps('kimlik_arka_yuz').value} alt=""
                                 mb="md"/>
                        )}
                        <Flex my="xs" align="center" justify="start">
                          {form.getInputProps('kimlik_arka_yuz').value ? (
                            <Button leftIcon={<IconTrash size={14}/>}
                                    type="button"
                                    color="red"
                                    onClick={handleClearKimlikArkaYuz}>Temizle</Button>
                          ) : (
                            <FileButton onChange={file => setKimlikArkaYuzDosya(file)}
                                        accept="image/png,image/jpeg,image/jpg">
                              {(props) => {
                                return <Button leftIcon={<IconFile size={14}/>}
                                               color="green"
                                               {...props}>Dosya seç</Button>
                              }}
                            </FileButton>
                          )}
                        </Flex>
                      </Input.Wrapper>
                    </Grid.Col>
                    {dealer !== null ? (
                      <>
                        <Grid.Col xs={12} sm={6} lg={4}>
                          <Input.Wrapper label="Abonelik sözleşmesi"
                                         description="Abonelik sözleşmesi için dosyalar yükleyin"
                                         withAsterisk
                                         {...form.getInputProps('abonelik_sozlesmesi')}>
                            {abonelikSozlesmeDosyalar.length > 0 && (
                              <Flex direction="column" mt="xs">
                                {abonelikSozlesmeDosyalar.map((file, index) => (
                                  <Flex key={index} align="center" justify="start" mb="xs">
                                    <ActionIcon variant="outline"
                                                size="xs"
                                                color="red"
                                                mr="xs"
                                                onClick={() => setAbonelikSozlesmeDosyalar(
                                                  abonelikSozlesmeDosyalar.filter(item => item !== file))}>
                                      <IconTrash size="0.8rem"/>
                                    </ActionIcon>
                                    <Badge>{file.name}</Badge>
                                  </Flex>
                                ))}
                              </Flex>
                            )}
                            <Flex my="xs" align="center" justify="start">
                              <FileButton
                                multiple
                                onChange={files => handleAddAbonelikSozlesmesiDosyalar(files)}
                                accept="image/png,image/jpeg,image/jpg,application/pdf">
                                {(props) => {
                                  return <Button leftIcon={<IconFile size={14}/>}
                                                 color="green"
                                                 {...props}>Dosya seç</Button>
                                }}
                              </FileButton>
                              {abonelikSozlesmeDosyalar.length > 0 && (
                                <Button leftIcon={<IconTrash size={14}/>}
                                        type="button"
                                        color="red"
                                        ml="xs"
                                        onClick={handleClearAbonelikSozlesmesi}>Temizle</Button>
                              )}
                            </Flex>
                          </Input.Wrapper>
                        </Grid.Col>
                        {form.getInputProps('basvuru_tipi').value === '2' && (
                          <Grid.Col xs={12} sm={6} lg={4}>
                            <Input.Wrapper label="İşletmeci değişiklik formu"
                                           description="İşletmeci değişiklik formu yükleyin"
                                           withAsterisk
                                           {...form.getInputProps('isletmeci_degisiklik')}>
                              {isletmeciDegisiklikFormuDosya && (
                                <Badge mt="xs">{isletmeciDegisiklikFormuDosya.name}</Badge>
                              )}
                              <Flex my="xs" align="center" justify="start">
                                {form.getInputProps('isletmeci_degisiklik').value ? (
                                  <Button leftIcon={<IconTrash size={14}/>}
                                          type="button"
                                          color="red"
                                          onClick={handleClearIsletmeciDegisiklikFormu}>Temizle</Button>
                                ) : (
                                  <FileButton onChange={file => setIsletmeciDegisiklikFormuDosya(file)}
                                              accept="image/png,image/jpeg,image/jpg,application/pdf">
                                    {(props) => {
                                      return <Button leftIcon={<IconFile size={14}/>}
                                                     color="green"
                                                     {...props}>Dosya seç</Button>
                                    }}
                                  </FileButton>
                                )}
                              </Flex>
                            </Input.Wrapper>
                          </Grid.Col>
                        )}
                      </>
                    ) : (
                      <Grid.Col xs={12} sm={6} lg={4}>
                        <Input.Wrapper label="İmza"
                                       description="Butona tıklayınca açılan pencerede imzanızı atın"
                                       withAsterisk
                                       {...form.getInputProps('imza')}>
                          {form.getInputProps('imza').value && (
                            <Image mx="auto" radius="md" src={form.getInputProps('imza').value} alt="" mb="md"/>
                          )}
                          <Input type="hidden" {...form.getInputProps('imza')}/>
                          <Flex my="xs" align="center" justify="start">
                            {form.getInputProps('imza').value ? (
                              <Button leftIcon={<IconTrash size={14}/>}
                                      type="button"
                                      color="red"
                                      size="sm"
                                      onClick={handleClearSignature}>Temizle</Button>
                            ) : (
                              <Button leftIcon={<IconSignature size={14}/>}
                                      type="button"
                                      color="green"
                                      size="sm"
                                      mr="sm"
                                      onClick={() => setSignModalOpened(true)}>İmzala</Button>
                            )}
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {form.getInputProps('abonelik_tipi').value === '2' && (
                      <>
                        <Grid.Col xs={12} sm={6} lg={4}>
                          <Input.Wrapper label="Vergi levhası"
                                         description="Vergi levhası yükleyin"
                                         withAsterisk
                                         {...form.getInputProps('vergi_levhasi')}>
                            {vergiLevhasiDosya && (
                              <Badge mt="xs">{vergiLevhasiDosya.name}</Badge>
                            )}
                            <Flex my="xs" align="center" justify="start">
                              {form.getInputProps('vergi_levhasi').value ? (
                                <Button leftIcon={<IconTrash size={14}/>}
                                        type="button"
                                        color="red"
                                        onClick={handleClearVergiLevhasi}>Temizle</Button>
                              ) : (
                                <FileButton onChange={file => setVergiLevhasiDosya(file)}
                                            accept="image/png,image/jpeg,image/jpg,application/pdf">
                                  {(props) => {
                                    return <Button leftIcon={<IconFile size={14}/>}
                                                   color="green"
                                                   {...props}>Dosya seç</Button>
                                  }}
                                </FileButton>
                              )}
                            </Flex>
                          </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6} lg={4}>
                          <Input.Wrapper label="İmza sirküsü"
                                         description="İmza sirküsü yükleyin"
                                         withAsterisk
                                         {...form.getInputProps('imza_sirkusu')}>
                            {imzaSirkusuDosya && (
                              <Badge mt="xs">{imzaSirkusuDosya.name}</Badge>
                            )}
                            <Flex my="xs" align="center" justify="start">
                              {form.getInputProps('imza_sirkusu').value ? (
                                <Button leftIcon={<IconTrash size={14}/>}
                                        type="button"
                                        color="red"
                                        onClick={handleClearImzaSirkusu}>Temizle</Button>
                              ) : (
                                <FileButton onChange={file => setImzaSirkusuDosya(file)}
                                            accept="image/png,image/jpeg,image/jpg,application/pdf">
                                  {(props) => {
                                    return <Button leftIcon={<IconFile size={14}/>}
                                                   color="green"
                                                   {...props}>Dosya seç</Button>
                                  }}
                                </FileButton>
                              )}
                            </Flex>
                          </Input.Wrapper>
                        </Grid.Col>
                      </>
                    )}
                  </Grid>
                  {engelliIndirim && (
                    <>
                      {form.getInputProps('engelli_sehit_gazi_yakin').value === '2' && (
                        <Grid>
                          <Grid.Col xs={12} sm={6}>
                            <Input.Wrapper label="Engelli/Şehit/Gazi nüfus cüzdani ön yüzü"
                                           description="Engelli/Şehit/Gazi nüfus cüzdani ön yüzünün resmini yükleyin"
                                           withAsterisk
                                           {...form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_on_yuz')}>
                              {form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_on_yuz').value && (
                                <Image mx="auto" radius="md"
                                       src={form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_on_yuz').value} alt=""
                                       mb="md"/>
                              )}
                              <Flex my="xs" align="center" justify="start">
                                {form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_on_yuz').value ? (
                                  <Button leftIcon={<IconTrash size={14}/>}
                                          type="button"
                                          color="red"
                                          onClick={handleClearEngelliSehitGaziYakinNufusCuzdaniOnYuz}>Temizle</Button>
                                ) : (
                                  <FileButton onChange={file => setEngelliSehitGaziYakinNufusCuzdaniOnYuzDosya(file)}
                                              accept="image/png,image/jpeg,image/jpg">
                                    {(props) => {
                                      return <Button leftIcon={<IconFile size={14}/>}
                                                     color="green"
                                                     {...props}>Dosya seç</Button>
                                    }}
                                  </FileButton>
                                )}
                              </Flex>
                            </Input.Wrapper>
                          </Grid.Col>
                          <Grid.Col xs={12} sm={6}>
                            <Input.Wrapper label="Engelli/Şehit/Gazi nüfus cüzdani arka yüzü"
                                           description="Engelli/Şehit/Gazi nüfus cüzdani arka yüzünün resmini yükleyin"
                                           withAsterisk
                                           {...form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_arka_yuz')}>
                              {form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_arka_yuz').value && (
                                <Image mx="auto" radius="md"
                                       src={form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_arka_yuz').value}
                                       alt=""
                                       mb="md"/>
                              )}
                              <Flex my="xs" align="center" justify="start">
                                {form.getInputProps('engelli_sehit_gazi_nufus_cuzdani_arka_yuz').value ? (
                                  <Button leftIcon={<IconTrash size={14}/>}
                                          type="button"
                                          color="red"
                                          onClick={handleClearEngelliSehitGaziYakinNufusCuzdaniArkaYuz}>Temizle</Button>
                                ) : (
                                  <FileButton onChange={file => setEngelliSehitGaziYakinNufusCuzdaniArkaYuzDosya(file)}
                                              accept="image/png,image/jpeg,image/jpg">
                                    {(props) => {
                                      return <Button leftIcon={<IconFile size={14}/>}
                                                     color="green"
                                                     {...props}>Dosya seç</Button>
                                    }}
                                  </FileButton>
                                )}
                              </Flex>
                            </Input.Wrapper>
                          </Grid.Col>
                        </Grid>
                      )}
                      <Grid>
                        <Grid.Col xs={12} sm={6}>
                          <Input.Wrapper label="Engelli/Şehit/Gazi kimlik ya da sağlık raporu ön yüzü"
                                         description="Engelli/Şehit/Gazi kimlik ya da sağlık raporu ön yüzünün resmini yükleyin"
                                         withAsterisk
                                         {...form.getInputProps('engelli_sehit_gazi_kimlik_rapor_on_yuz')}>
                            {form.getInputProps('engelli_sehit_gazi_kimlik_rapor_on_yuz').value && (
                              <Image mx="auto" radius="md"
                                     src={form.getInputProps('engelli_sehit_gazi_kimlik_rapor_on_yuz').value} alt=""
                                     mb="md"/>
                            )}
                            <Flex my="xs" align="center" justify="start">
                              {form.getInputProps('engelli_sehit_gazi_kimlik_rapor_on_yuz').value ? (
                                <Button leftIcon={<IconTrash size={14}/>}
                                        type="button"
                                        color="red"
                                        onClick={handleClearEngelliSehitGaziKimlikOnYuz}>Temizle</Button>
                              ) : (
                                <FileButton onChange={file => setEngelliSehitGaziKimlikRaporOnYuzDosya(file)}
                                            accept="image/png,image/jpeg,image/jpg">
                                  {(props) => {
                                    return <Button leftIcon={<IconFile size={14}/>}
                                                   color="green"
                                                   {...props}>Dosya seç</Button>
                                  }}
                                </FileButton>
                              )}
                            </Flex>
                          </Input.Wrapper>
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                          <Input.Wrapper label="Engelli/Şehit/Gazi kimlik ya da sağlık raporu arka yüzü"
                                         description="Engelli/Şehit/Gazi kimlik ya da sağlık raporu arka yüzünün resmini yükleyin"
                                         withAsterisk
                                         {...form.getInputProps('engelli_sehit_gazi_kimlik_rapor_arka_yuz')}>
                            {form.getInputProps('engelli_sehit_gazi_kimlik_rapor_arka_yuz').value && (
                              <Image mx="auto" radius="md"
                                     src={form.getInputProps('engelli_sehit_gazi_kimlik_rapor_arka_yuz').value} alt=""
                                     mb="md"/>
                            )}
                            <Flex my="xs" align="center" justify="start">
                              {form.getInputProps('engelli_sehit_gazi_kimlik_rapor_arka_yuz').value ? (
                                <Button leftIcon={<IconTrash size={14}/>}
                                        type="button"
                                        color="red"
                                        onClick={handleClearEngelliSehitGaziKimlikArkaYuz}>Temizle</Button>
                              ) : (
                                <FileButton onChange={file => setEngelliSehitGaziKimlikRaporArkaYuzDosya(file)}
                                            accept="image/png,image/jpeg,image/jpg">
                                  {(props) => {
                                    return <Button leftIcon={<IconFile size={14}/>}
                                                   color="green"
                                                   {...props}>Dosya seç</Button>
                                  }}
                                </FileButton>
                              )}
                            </Flex>
                          </Input.Wrapper>
                        </Grid.Col>
                      </Grid>
                    </>
                  )}
                </fieldset>
                <Flex align="center" justify="center" mt="xl" mb="xl">
                  <Checkbox label="Formda girmiş olduğum bilgilerin doğruluğunu onaylıyorum."
                            {...form.getInputProps('sartlar', {type: 'checkbox'})}/>
                </Flex>
                {isLoading && (
                  <Text color="green" my="xs" align="center">Başvurunuz oluşturuluyor, lütfen bekleyin...</Text>
                )}
                <Flex align="center" justify="center" mb="md">
                  <Button type="submit"
                          size="xl"
                          uppercase
                          disabled={
                            tarifeTipi === null ||
                            tarifeTipi === 'yok' ||
                            form.getInputProps('sartlar').value !== true ||
                            isLoading}
                          loading={isLoading}>Başvuruyu Tamamla</Button>
                </Flex>
              </form>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default ApplicationForm;

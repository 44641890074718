import {useCallback, useEffect, useState} from "react";
import {Alert, Flex, Loader, Modal, useMantineTheme} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {IconInfoCircle, IconX} from "@tabler/icons-react";
import ApiClient from "../../utils/ApiClient";
import GsmCheckForm from "./GsmCheckForm";

type PropsType = {
  show: boolean;
  oturumId: string;
  phone: string | null;
  onVerified: () => void;
  onClose?: () => void;
}

const GsmCheckFormModal = ({show, oturumId, phone, onVerified, onClose}: PropsType) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(show);
  const [loading, setLoading] = useState(false);
  const [sentCode, setSentCode] = useState(false);

  useEffect(() => {
    setOpened(show);
  }, [show]);

  const handleSendCode = useCallback(async () => {
    if (!oturumId || !phone) return;

    try {
      setLoading(true);
      setSentCode(false);
      await ApiClient.cepTelefonuKodGonder(oturumId, phone);
      setSentCode(true);
    } catch (e) {
      console.log(e);
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        title: "Hata!",
        message: 'Bir sorun oluştu, lütfen tekrar deneyin.',
        color: 'red',
        icon: <IconX/>,
        loading: false,
      })
    } finally {
      setLoading(false);
    }
  }, [oturumId, phone]);

  useEffect(() => {
    if (opened) {
      (async () => {
        await handleSendCode();
      })();
    }
  }, [handleSendCode, opened]);

  const handleClose = () => {
    setOpened(false);
    onClose && onClose();
  }

  const handleVerified = () => {
    onVerified && onVerified();
    handleClose();
  }

  return (
    <Modal title="Cep Telefonu Doğrulama"
           centered
           opened={opened}
           overlayProps={{
             color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
             opacity: 0.55,
             blur: 3,
           }}
           onClose={handleClose}>
      {sentCode ? (
        <>
          <GsmCheckForm oturumId={oturumId}
                        telefon={phone!}
                        onVerified={handleVerified}/>
        </>
      ) : (
        <Flex align="center" justify="center" wrap="wrap" gap="md">
          <Alert icon={<IconInfoCircle size="1rem"/>} color="gray" mb="md">
            <strong>{phone}</strong> numaralı cep telefonunuza doğrulama mesajı gönderiliyor
          </Alert>
          {loading && (
            <Loader size={40} style={{margin: 'auto'}}/>
          )}
        </Flex>
      )}
    </Modal>
  );
}

export default GsmCheckFormModal;
